<template>
  <tr>
    <td>
      {{ form.name }}
    </td>
    <td>
      <v-btn icon :to="{ name: 'forms.show', params: { formId: formId } }">
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapFormGetters,
  mapState: mapFormState
} = createNamespacedHelpers("forms");

export default {
  name: "form-table-row",
  props: {
    formId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapFormGetters([FIND_BY_ID]),
    ...mapFormState(["form"]),
    form() {
      return this[FIND_BY_ID](this.formId);
    }
  }
};
</script>
