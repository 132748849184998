<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Form"
      store="form"
      @save-success="
        ({ id }) => $router.push({ name: 'forms.show', params: { formId: id } })
      "
    />
    <collection-form />
  </v-card>
</template>

<script type="text/babel">
import formModule from "@/store/modules/form";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "@/views/helpers";
import CollectionForm from "../../components/form/Form";

const { mapGetters } = createNamespacedHelpers("form");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "form",
  formModule
);

export default {
  name: "form-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CollectionForm,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
