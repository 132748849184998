<template>
  <v-dialog lazy v-model="internalDialog" max-width="800">
    <v-card>
      <v-carousel v-model="indexOfCarousel" :cycle="false" max="800">
        <v-carousel-item
          v-for="(item, i) in images"
          :key="i"
          :src="`http://cdn.buldit.be/${item.filePath}`"
        ></v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script type="text/babel">
import { cloneDeep } from "lodash";

export default {
  name: "annotation-img-dialog",
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    carouselIndex: {
      type: Number,
      default: 0
    },
    images: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({}),
  computed: {
    internalDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("close");
      }
    },
    indexOfCarousel: {
      get() {
        return this.carouselIndex;
      },
      set() {
        cloneDeep(this.carouselIndex);
      }
    }
  },
  watch: {},
  methods: {}
};
</script>
