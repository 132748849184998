var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showChild)?_c('table-overview',{attrs:{"title":"Forms","store":"forms","headers":[
    { text: 'Name', value: 'name' },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'forms_view'
    }
  ],"actions":_vm.actions,"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('form-table-row',{attrs:{"form-id":item}})}}],null,false,2390166853)},[_c('forms-table-filters',{attrs:{"slot":"filters"},slot:"filters"})],1):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }