<template>
  <div>
    <collection-form />
  </div>
</template>
<script type="text/babel">
import CollectionForm from "@/components/form/Form";

export default {
  name: "form-dashboard-view",
  components: {
    CollectionForm
  }
};
</script>
