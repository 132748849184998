<template>
  <v-container fluid>
    <v-text-field v-model="title" label="Title" :counter="255" />
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="date"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
    >
      <v-text-field slot="activator" v-model="date" label="Select Date" />
      <v-date-picker v-model="date" no-title scrollable>
        <v-spacer />
        <v-btn flat color="primary" @click="menu = false">
          Cancel
        </v-btn>
        <v-btn flat color="primary" @click="$refs.menu.save(date)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-text-field v-model="annotationType" label="Type" disabled />
    <v-autocomplete-annotation-sub-type
      v-model="subTypes"
      :type="annotationType"
      item-text="title"
      multiple
    />
    <v-upload-image
      v-model="file"
      preview
      label="Annotation picture"
      placeholder="Click to upload a new annotation picture"
      :error-messages="errors.collect('files')"
    />
    <v-layout row>
      <v-flex v-for="(image, index) in images.slice(0, 4)" :key="index" xs3>
        <v-card>
          <div class="img-container">
            <a :href="`http://cdn.buldit.be/${image.filePath}`" target="_blank">
              <v-img
                :src="`http://cdn.buldit.be/${image.filePath}`"
                aspect-ratio="1"
              />
            </a>
            <v-btn
              fab
              dark
              small
              color="primary"
              class="img-button"
              @click="deleteImage(image.id)"
              ><v-icon dark>far fa-trash-alt</v-icon></v-btn
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-subheader class="body-warning" v-if="isBodyFilled === false"
      >Body is verplicht!</v-subheader
    >
    <div style="min-height: 200px;">
      <quill-editor
        ref="textEditor"
        v-model="body"
        :options="editorOption"
        style="height: 100px !important;"
      />
    </div>
    <annotation-img-dialog
      :show-dialog="showDialog"
      :carousel-index="imageIndex"
      :images="images"
      @close="showDialog = false"
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteAnnotationSubType from "../annotation-sub-type/VAutocompleteAnnotationSubType";
import { isNil } from "lodash";
import AnnotationImgDialog from "@/components/annotation/AnnotationImgDialog";
import VUploadImage from "@/components/generic/input/VUploadImage";

const { mapComputed } = createNamespacedHelpers("annotation");

export default {
  name: "reworked-annotation",
  components: {
    VUploadImage,
    AnnotationImgDialog,
    VAutocompleteAnnotationSubType
  },
  inject: ["$validator"],
  props: {
    formId: {
      type: Number,
      required: false
    },
    annotationType: {
      type: String,
      required: false
    }
  },
  data: () => ({
    menu: false,
    showDialog: false,
    imageIndex: null,
    editorOption: {
      theme: "snow",
      modules: {
        markdownShortcuts: {},
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          ["link"]
        ]
      }
    },
    isBodyFilled: false
  }),
  created() {
    this.$store.commit("annotation/setType", this.annotationType);
    this.$store.commit("annotation/setForm", this.formId);
  },
  computed: {
    ...mapComputed([
      "id",
      "title",
      "type",
      "subTypes",
      "date",
      "body",
      "files",
      "file"
    ]),
    images() {
      if (Array.isArray(this.files)) {
        return this.files.filter(e => {
          const extension = e.fileName ? e.fileName.split(".").pop() : null;
          return (
            extension === "jpg" || extension === "jpeg" || extension === "png"
          );
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    body: {
      handler(newVal) {
        if (!isNil(newVal) && newVal !== "") {
          this.isBodyFilled = true;
        } else if (newVal === "") {
          this.isBodyFilled = false;
        }
      }
    }
  },
  methods: {
    openDialog(index) {
      this.showDialog = true;
      this.imageIndex = index;
    },
    deleteImage(fileId) {
      this.$store.dispatch("annotation/deleteImage", {
        annotationId: this.id,
        fileId: fileId
      });
      this.$emit("image-deleted");
    }
  }
};
</script>
<style scoped>
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg3-custom {
    width: 33.333%;
    max-width: 33.333%;
    flex-basis: 33.333%;
  }
}

p {
  margin-bottom: 5px;
}
.img-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.img-container img {
  width: 100%;
  height: auto;
}

.img-button {
  position: absolute;
  top: 80%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  text-align: center;
}

.body-warning {
  color: red;
}
</style>
