import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import { SAVE } from "../../templates/crud/action-types";
import { SET_IS_LOADING, SET_MODEL } from "../../templates/crud/mutation-types";
import { isNil } from "lodash";

export default {
  ...crudActions(api.annotation, "annotation"),
  async deleteImage({ commit }, { annotationId, fileId }) {
    await api.annotation.deleteImage(annotationId, fileId);
    commit("deleteFile", fileId);
  },
  async [SAVE]({ state, commit, dispatch }) {
    commit(SET_IS_LOADING, true);
    const isCreate = isNil(state.model.id);
    const entityName = "annotation";
    const { entities, result } = isCreate
      ? await api.annotation.create(state.model)
      : await api.annotation.update(state.model.id, state.model);
    commit("setFile", null);
    commit(SET_MODEL, { entities, id: result });
    commit(SET_IS_LOADING, false);

    dispatch(
      "snackbar/addCrudSuccess",
      { entityName, isCreate },
      { root: true }
    );

    return result;
  }
};
