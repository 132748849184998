<template>
  <v-form>
    <v-autocomplete-subject v-model="subjects" multiple />
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";

const { mapComputedFilters } = createNamespacedHelpers("forms");

export default {
  name: "form-table-filters",
  components: { VAutocompleteSubject },
  data: () => ({}),
  computed: {
    ...mapComputedFilters(["subjects"])
  }
};
</script>
