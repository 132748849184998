<template>
  <v-container fluid>
    <v-layout row>
      <v-flex>
        <v-text-field
          v-model="name"
          v-validate="'required|max:255'"
          label="Name"
          autocomplete="not-my-first-name"
          :counter="255"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          required
        />
        <v-autocomplete
          v-model="redirectType"
          :items="redirectTypes"
          label="Redirect Type"
        />
        <v-text-field
          v-model="redirectUrl"
          v-validate="'max:255'"
          label="Redirect url"
          autocomplete="not-my-redirect-url"
          :counter="255"
          :error-messages="errors.collect('redirectUrl')"
          data-vv-name="redirectUrl"
          required
        />
        <v-select-locale v-model="locales" multiple />
        <v-flex pa-0>
          <v-btn-toggle v-if="hasUuid" v-model="locale" mandatory depressed>
            <v-btn :value="null" color="primary">
              AGNOSTIC
            </v-btn>
            <v-btn
              v-for="(locale, index) in mappedLocales"
              :key="index"
              :value="locale.code"
              color="primary lighten-4"
            >
              {{ locale.name }}
            </v-btn>
          </v-btn-toggle>
        </v-flex>
        <v-layout style="margin-top: 15px" row v-if="hasUuid">
          <pre>
            <!--eslint-disable-->
    &ltdiv id="dynamic-form-container"&gt&lt/div&gt
    &ltscript src="{{ embedUrl }}"&gt&lt/script&gt
    &ltscript&gt
      document.addEventListener("DOMContentLoaded", function () {
      window.formBuilder.init({{ initArguments }});
    });
    &lt/script&gt
          </pre>
        </v-layout>
      </v-flex>
    </v-layout>
    <form-annotations style="margin-bottom: 20px" />
    <from-annotation-dialog
      :create-annotation-dialog="annotationDialog"
      :form-id="id"
      @close="annotationDialog = false"
    />
    <v-btn
      fab
      dark
      small
      :top="false"
      :bottom="true"
      :right="true"
      :left="false"
      fixed
      color="pink"
      @click="openAnnotationDialog"
    >
      <v-icon>far fa-fw fa-edit</v-icon>
    </v-btn>
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VSelectLocale from "../locale/VSelectLocale";
import { isNil } from "lodash";
import FromAnnotationDialog from "@/components/form/FomAnnotationDialog";
import FormAnnotations from "@/components/form/FormAnnotations";
const { mapComputed } = createNamespacedHelpers("form");

export default {
  name: "collection-form",
  components: { FormAnnotations, FromAnnotationDialog, VSelectLocale },
  inject: ["$validator"],
  data: () => ({
    locale: null,
    redirectTypes: ["URL", "Default"],
    annotationDialog: false
  }),
  computed: {
    ...mapComputed([
      "id",
      "name",
      "redirectType",
      "redirectUrl",
      "locales",
      "uuid"
    ]),
    initArguments() {
      if (isNil(this.locale) || this.locale === 0) {
        return '"' + this.uuid + '"';
      }
      return '"' + this.uuid + '", "' + this.locale + '"';
    },
    hasUuid() {
      return !isNil(this.uuid);
    },
    embedUrl() {
      return process.env.VUE_APP_MIX_EMBED_JS_URL;
    },
    mappedLocales() {
      return this.$store.getters["form/mappedLocales"];
    }
  },
  methods: {
    openAnnotationDialog() {
      this.$store.commit("form/openAnnotationDialog", true);
    }
  }
};
</script>
