<template>
  <tr>
    <td style="padding: 0 12px;">
      <v-icon :style="`color: ${typeColor}`" class="mr-3">
        {{ typeColor ? "brightness_1" : "panorama_fish_eye" }}
      </v-icon>
      {{ formatDate(annotation.date) }}
    </td>
    <td>
      {{ typeName }}
    </td>
    <td>
      <v-chip v-for="(subType, index) in subTypes" :key="index" label small>
        {{ subType.title }}
      </v-chip>
    </td>
    <td style="padding: 0 12px;">
      {{ annotation.author.firstName }} {{ annotation.author.lastName }}
    </td>
    <td style="text-align: center;">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div class="tableBodyRow" v-html="annotation.body" v-on="on" />
        </template>
        <span v-html="annotation.body"></span>
      </v-tooltip>
    </td>
    <td style="text-align: right;">
      <template v-for="(image, index) in images">
        <v-btn
          :key="index"
          v-if="hasImages"
          :href="`http://cdn.buldit.be/${image.filePath}`"
          target="_blank"
        >
          <v-img
            class="btn-img"
            :src="`http://cdn.buldit.be/${image.filePath}`"
            aspect-ratio="1"
          />
        </v-btn>
      </template>
    </td>
    <td>
      <v-btn icon @click="openDialog()">
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
    <annotation-img-dialog
      :show-dialog="showImgDialog"
      :images="images"
      @close="showImgDialog = false"
    />
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import { isNil } from "lodash";
import AnnotationImgDialog from "@/components/annotation/AnnotationImgDialog";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapAnnotationGetters,
  mapState: mapAnnotationState
} = createNamespacedHelpers("form/annotations");

export default {
  name: "from-annotation-table-row",
  components: { AnnotationImgDialog },
  props: {
    annotationId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    showImgDialog: false
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapAnnotationGetters([FIND_BY_ID]),
    ...mapAnnotationState(["annotationType", "annotationSubType"]),
    annotation() {
      return this[FIND_BY_ID](this.annotationId);
    },
    typeColor() {
      return !isNil(this.annotation.type)
        ? this.annotationType[this.annotation.type].color
        : null;
    },
    typeName() {
      return !isNil(this.annotation.type)
        ? this.annotationType[this.annotation.type].name
        : null;
    },
    subTypes: function() {
      return this.annotation.subTypes.map(
        subTypeId => this.annotationSubType[subTypeId]
      );
    },
    hasImages() {
      return this.annotation.files.length > 0;
    },
    files() {
      return this.$store.getters["form/annotations/files"](this.annotationId);
    },
    images() {
      let imgFiles = [];
      this.files.forEach(e => {
        const extension = e.fileName.split(".").pop();
        if (
          extension === "jpg" ||
          extension === "jpeg" ||
          extension === "png"
        ) {
          imgFiles.push(e);
        }
      });
      return imgFiles;
    }
  },
  methods: {
    openDialog() {
      this.$store.commit("form/setAnnotationDialogId", this.annotation.id);
      this.$store.commit("form/openAnnotationDialog", true);
    },
    openImgDialog() {
      this.showImgDialog = true;
    },
    formatDate(date) {
      //need to do it with js because apexCharts expects american format.
      let d = new Date(date);
      return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
        .map(n => (n < 10 ? `0${n}` : `${n}`))
        .join("/");
    }
  }
};
</script>
<style scoped>
.tableBodyRow {
  max-height: 48px;
  overflow: hidden;
}

.btn-img {
  max-width: 30px;
  height: 30px;
}

.v-btn {
  min-width: 30px;
}

a {
  padding: 0;
}
</style>
