<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Form"
      store="form"
      icon="far fa-fw fa-globe"
      :tabs="tabs"
    >
      <template slot="toolbarActions">
        <v-btn icon @click="publish">
          <v-icon>far fa-upload</v-icon>
        </v-btn>
      </template>
    </crud-header>
  </v-card>
</template>

<script type="text/babel">
import formModule from "@/store/modules/form";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "@/views/helpers";
import api from "@/api";

const { mapGetters } = createNamespacedHelpers("form");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "form",
  formModule
);

export default {
  name: "form-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CrudHeader
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: `Form - ${this.name}`
    };
  },
  computed: {
    ...mapGetters(["name", "id", "form"]),
    tabs() {
      return [
        {
          name: "Dashboard",
          to: { name: "forms.show", params: { formId: this.id } }
        },
        {
          name: "Subjects",
          href: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/collection/form/${this.id}/subject`
        },
        {
          name: "Questions",
          href: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/collection/form/${this.id}/question`
        },
        {
          name: "Preview",
          href: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/collection/form/${this.id}/preview`
        },
        {
          name: "Published",
          href: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/collection/form/${this.id}/published`
        },
        {
          name: "Leads",
          href: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/collection/form/${this.id}/lead`
        },
        {
          name: "Raw Leads",
          href: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/collection/form/${this.id}/raw-lead`
        }
      ];
    }
  },
  methods: {
    async publish() {
      await api.form.publish(this.id, this.form);
    }
  },
  ...detailViewLifecycleMethods
};
</script>
