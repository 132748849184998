import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  uuid: null,
  name: null,
  redirectType: "Default",
  redirectUrl: null,
  locales: []
});

export default () => ({
  ...initialCrudState(initialModel),
  locale: {},
  isAnnotationDialogOpen: false,
  annotationDialogId: null
});
