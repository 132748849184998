<template>
  <table-overview
    v-if="!showChild"
    title="Forms"
    store="forms"
    :headers="[
      { text: 'Name', value: 'name' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'forms_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <form-table-row slot="table-row" slot-scope="{ item }" :form-id="item" />
    <forms-table-filters slot="filters" />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import TableOverview from "@/components/generic/overview/TableOverview";
import formsModule from "@/store/modules/forms";
import FormTableRow from "@/components/form/FormTableRow";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import FormsTableFilters from "@/components/form/FormTableFilters";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "forms",
  formsModule
);

export default {
  name: "forms-view",
  components: {
    FormsTableFilters,
    FormTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "forms.create" },
        rolesOrPermissions: "forms_create"
      }
    ]
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  },
  ...overviewLifecycleMethods
};
</script>
