import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  title: null,
  author: null,
  lastEditedBy: null,
  type: null,
  subTypes: [],
  date: null,
  body: null,
  keywords: [],
  subjects: [],
  websites: [],
  articles: [],
  files: [],
  file: null
});

export default () => ({
  ...initialCrudState(initialModel),
  annotationType: {},
  annotationSubType: {},
  file: {}
});
