import crudMutations from "@/store/templates/crud/mutations";
import { REVERT } from "../../templates/crud/mutation-types";

export default {
  setTitle(state, title) {
    state.model.title = title;
  },
  setType(state, type) {
    state.model.type = type;
  },
  setSubTypes(state, subTypes) {
    state.model.subTypes = subTypes;
  },
  setBody(state, body) {
    state.model.body = body;
  },
  setDate(state, date) {
    state.model.date = date;
  },
  setKeywords(state, keywords) {
    state.model.keywords = keywords;
  },
  setForm(state, form) {
    state.model.form = form;
  },
  setSubjects(state, subjects) {
    state.model.subjects = subjects;
  },
  setArticles(state, articles) {
    state.model.articles = articles;
  },
  setWebsites(state, websites) {
    state.model.websites = websites;
  },
  setFile(state, file) {
    state.model.file = file;
  },
  deleteFile(state, fileId) {
    let index = state.model.files.indexOf(fileId);
    if (index !== -1) state.model.files.splice(index, 1);
  },
  ...crudMutations,
  [REVERT](state) {
    state.model.title = state.initialModel.title;
    state.model.subTypes = state.initialModel.subTypes;
    state.model.body = state.initialModel.body;
    state.model.date = state.initialModel.date;
    state.model.file = state.initialModel.file;
  }
};
