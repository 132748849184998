import crudGetters from "@/store/templates/crud/getters";

export default {
  ...crudGetters,
  form: state => state.model,
  id: state => state.model.id,
  uuid: state => state.model.uuid,
  name: state => state.model.name,
  redirectType: state => state.model.redirectType,
  redirectUrl: state => state.model.redirectUrl,
  locales: state => state.model.locales,
  mappedLocales: state =>
    state.model.locales.map(localeId => state.locale[localeId]),
  isAnnotationDialogOpen: state => state.isAnnotationDialogOpen,
  annotationDialogId: state => state.annotationDialogId
};
