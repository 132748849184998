import crudGetters from "@/store/templates/crud/getters";
import { IS_CHANGED } from "../../templates/crud/getter-types";
import { isChanged } from "../../change";
import { cloneDeep, isNil } from "lodash";

export default {
  annotation: state => state.model,
  id: state => state.model.id,
  title: state => state.model.title,
  author: state => state.model.author,
  lastEditedBy: state => state.model.lastEditedBy,
  type: state => state.model.type,
  subTypes: state => state.model.subTypes,
  date: state => state.model.date,
  body: state => state.model.body,
  keywords: state => state.model.keywords,
  subjects: state => state.model.subjects,
  articles: state => state.model.articles,
  websites: state => state.model.websites,
  files: state =>
    Array.isArray(state.model.files)
      ? state.model.files.map(fileId => state.file[fileId])
      : state.model.files,
  file: state => state.model.file,
  ...crudGetters,
  [IS_CHANGED]: state => {
    let model = cloneDeep(state.model);
    let initialModel = cloneDeep(state.initialModel);
    delete model["type"];
    delete model["articles"];
    delete model["websites"];
    delete initialModel["type"];
    delete initialModel["articles"];
    delete initialModel["websites"];
    const isModelChanged = isChanged(model, initialModel);
    const isBodyFilled = !isNil(state.model.body) && state.model.body !== "";
    if (isModelChanged && isBodyFilled) {
      return true;
    } else {
      return false;
    }
  }
};
